<template>
    <ddcard class="box-card" :collapsed="true" :inactive="!active">
        <div slot="title" class="clearfix">
            <el-switch v-model="active" :active-value="1" :inactive-value="0" />
            <span style="margin-left: 20px">Oblio</span>
        </div>

        <Box type="info" :active="true" style="margin-top: 0; margin-bottom: 10px">
            <div slot="box-body">
                <ul style="font-size: 12px">
                    <li>Adresa de email reprezinta adresa de email asociata contului Oblio</li>
                    <li>Token-ul se poate obtine Setari > Date Cont > API secret</li>
                </ul>
            </div>
        </Box>

        <el-row v-if="active" :gutter="30">
            <el-col :xs="24" :md="12">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                        <el-form-item :label="$t('sales.billing.email')" prop="oblio.email">
                            <el-input v-model="email"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12">
                        <el-form-item :label="$t('sales.billing.token')" prop="oblio.token">
                            <el-input v-model="token"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.product_reference')" prop="oblio.product_reference">
                            <el-select class="fullWidth" v-model="product_reference" filterable>
                                <el-option
                                    value="products_model"
                                    :label="$t('sales.billing.products_model')"
                                ></el-option>
                                <el-option value="vendor_ext_id" :label="$t('sales.billing.vendor_ext_id')"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.invoice_serie')" prop="oblio.invoice_serie">
                            <el-input v-model="invoice_serie"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.proforma_serie')" prop="oblio.proforma_serie">
                            <el-input v-model="proforma_serie"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-col>

            <el-col :xs="24" :md="12">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.warehouse')" prop="oblio.warehouse">
                            <el-input v-model="warehouse"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.product_type')" prop="oblio.warehouse_unit">
                            <el-select class="fullWidth" v-model="warehouse_unit" filterable>
                                <el-option value="produs_finit" label="Produs finit"></el-option>
                                <el-option value="serviciu" label="Serviciu"></el-option>
                                <el-option value="marfa" label="Marfa"></el-option>
                                <el-option value="semifabricate" label="Semifabricate"></el-option>
                                <el-option value="produs_rezidual" label="Produs rezidual"></el-option>
                                <el-option value="produse_agricole" label="Produse agricole"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.shipping_type')" prop="oblio.shipping_type">
                            <el-select class="fullWidth" v-model="shipping_type" filterable>
                                <el-option value="product" label="Produs"></el-option>
                                <el-option value="service" label="Serviciu"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.shipping_code')" prop="oblio.shipping_code">
                            <el-input v-model="shipping_code"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-checkbox
                            v-model="issue_payment"
                            :true-label="1"
                            :false-label="0"
                            :label="$t('sales.billing.issue_payment')"
                        />
                    </el-col>

                    <el-col :span="12">
                        <el-checkbox
                            v-model="use_stock"
                            :true-label="1"
                            :false-label="0"
                            :label="$t('sales.billing.use_stock')"
                        />
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </ddcard>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
    name: 'Oblio',
    computed: {
        active: sync('account/billing@oblio.active'),
        email: sync('account/billing@oblio.email'),
        token: sync('account/billing@oblio.token'),
        product_reference: sync('account/billing@oblio.product_reference'),
        invoice_serie: sync('account/billing@oblio.invoice_serie'),
        proforma_serie: sync('account/billing@oblio.proforma_serie'),
        warehouse: sync('account/billing@oblio.warehouse'),
        warehouse_unit: sync('account/billing@oblio.warehouse_unit'),
        shipping_type: sync('account/billing@oblio.shipping_type'),
        shipping_code: sync('account/billing@oblio.shipping_code'),
        issue_payment: sync('account/billing@oblio.issue_payment'),
        use_stock: sync('account/billing@oblio.use_stock'),
    },
    components: {
        ddcard: () => import('@/components/DDCard'),
        Box: () => import('vue-info-box-element'),
    },
};
</script>
